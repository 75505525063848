/* src/components/BackToTopButton.css */
.back-to-top {
    position: fixed;
    bottom: 40px;
    left: 20px;
    z-index: 1000;
  }
  
  .back-to-top button {
    background-color: #1a2d42;
    color: white;
    border: none;
    border-radius: 58%;
    padding: 10px 20px;
    font-size: 16x;
    cursor: pointer;
    transition: opacity 0.5s, visibility 0.3s;
    -webkit-border-radius: 58%;
    -moz-border-radius: 58%;
    -ms-border-radius: 58%;
    -o-border-radius: 58%;
    -webkit-transition: opacity 0.3s, visibility 0.3s;
    -moz-transition: opacity 0.5s, visibility 0.3s;
    -ms-transition: opacity 0.5s, visibility 0.3s;
    -o-transition: opacity 0.5s, visibility 0.3s;
}
  
  .back-to-top button:hover {
    background-color: #0056b3;
  }
  