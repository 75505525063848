.navbar {
    background-color: #0D1B2A;
    /* background-color: #696969; */
    color: #FFFFFF;
    /* color: #fff; */
    padding: 10px 0;
    width: 100%;
    /* position: fixed; */
  }
  /* .OlaFolio{
    text-align: center;
    padding: 5px 15px;
  } */
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    /* border-radius: 22px;; */
  
}
  /* .logo:hover{
    background-color: #1B263B;
  } */

  /* Basic styles for the portfolio item */
.portfolio-item {
  position: relative;
  width: 45px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  margin: 20px;
  background-color: #1e1e1e; /* Background color for the item */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Initial shadow */
}

/* Glowing effect on hover */
.portfolio-item:hover {
  transform: scale(1.05); /* Slightly enlarge the item */
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.8), 
              0 0 60px rgba(255, 255, 255, 0.6), 
              0 0 90px rgba(255, 255, 255, 0.4); /* Glowing shadows */
}
  .navbar-logo a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
  }
  
  .navbar-links {
    display: flex;
    
  }
  .color1:hover {
    color: #0077b6; /* Deep Blue */
    box-shadow: 0 0 10px rgba(0, 119, 182, 0.8),
                0 0 20px rgba(0, 119, 182, 0.6),
                0 0 30px rgba(0, 119, 182, 0.4);
  }
  
  .color2:hover {
    color: #00a79d; /* Emerald Green */
    box-shadow: 0 0 10px rgba(0, 167, 157, 0.8),
                0 0 20px rgba(0, 167, 157, 0.6),
                0 0 30px rgba(0, 167, 157, 0.4);
  }
  
  .color3:hover {
    color: #6f42c1; /* Royal Purple */
    box-shadow: 0 0 10px rgba(111, 66, 193, 0.8),
                0 0 20px rgba(111, 66, 193, 0.6),
                0 0 30px rgba(111, 66, 193, 0.4);
  }
  
  .color4:hover {
    color: #ff6b35; /* Sunset Orange */
    box-shadow: 0 0 10px rgba(255, 107, 53, 0.8),
                0 0 20px rgba(255, 107, 53, 0.6),
                0 0 30px rgba(255, 107, 53, 0.4);
  }
  
  .color5:hover {
    color: #f7c59f; /* Golden Yellow */
    box-shadow: 0 0 10px rgba(247, 197, 159, 0.8),
                0 0 20px rgba(247, 197, 159, 0.6),
                0 0 30px rgba(247, 197, 159, 0.4);
  }
  
  .color6:hover {
    color: #00b4d8; /* Ocean Blue */
    box-shadow: 0 0 10px rgba(0, 180, 216, 0.8),
                0 0 20px rgba(0, 180, 216, 0.6),
                0 0 30px rgba(0, 180, 216, 0.4);
  }
  
  .color7:hover {
    color: #228b22; /* Forest Green */
    box-shadow: 0 0 10px rgba(34, 139, 34, 0.8),
                0 0 20px rgba(34, 139, 34, 0.6),
                0 0 30px rgba(34, 139, 34, 0.4);
  }
  
  .color8:hover {
    color: #ff85a2; /* Rose Pink */
    box-shadow: 0 0 10px rgba(255, 133, 162, 0.8),
                0 0 20px rgba(255, 133, 162, 0.6),
                0 0 30px rgba(255, 133, 162, 0.4);
  }
  
  .color9:hover {
    color: #008080; /* Teal Blue */
    box-shadow: 0 0 10px rgba(0, 128, 128, 0.8),
                0 0 20px rgba(0, 128, 128, 0.6),
                0 0 30px rgba(0, 128, 128, 0.4);
  }
  
  .color10:hover {
    color: #967bb6; /* Lavender */
    box-shadow: 0 0 10px rgba(150, 123, 182, 0.8),
                0 0 20px rgba(150, 123, 182, 0.6),
                0 0 30px rgba(150, 123, 182, 0.4);
  }
  
  .navbar-links a {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
  }
  .navbar-links a:hover {
    color: #778DA9;
    color: #ffd700; /* Change text color on hover */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 
              0 0 20px rgba(255, 255, 255, 0.6), 
              0 0 30px rgba(255, 255, 255, 0.4); 
  }
  .color12:hover {
    color: #1e90ff; /* Dodger Blue */
    box-shadow: 0 0 10px rgba(30, 144, 255, 0.8),
                0 0 20px rgba(30, 144, 255, 0.6),
                0 0 30px rgba(30, 144, 255, 0.4);
  }
  
  .color13:hover {
    color: #ff4500; /* Orange Red */
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.8),
                0 0 20px rgba(255, 69, 0, 0.6),
                0 0 30px rgba(255, 69, 0, 0.4);
  }
  
  .color14:hover {
    color: #8a2be2; /* Blue Violet */
    box-shadow: 0 0 10px rgba(138, 43, 226, 0.8),
                0 0 20px rgba(138, 43, 226, 0.6),
                0 0 30px rgba(138, 43, 226, 0.4);
  }
  
  .color15:hover {
    color: #4682b4; /* Steel Blue */
    box-shadow: 0 0 10px rgba(70, 130, 180, 0.8),
                0 0 20px rgba(70, 130, 180, 0.6),
                0 0 30px rgba(70, 130, 180, 0.4);
  }
  
  .color16:hover {
    color: #dc143c; /* Crimson */
    box-shadow: 0 0 10px rgba(220, 20, 60, 0.8),
                0 0 20px rgba(220, 20, 60, 0.6),
                0 0 30px rgba(220, 20, 60, 0.4);
  }
  
  .color17:hover {
    color: #20b2aa; /* Light Sea Green */
    box-shadow: 0 0 10px rgba(32, 178, 170, 0.8),
                0 0 20px rgba(32, 178, 170, 0.6),
                0 0 30px rgba(32, 178, 170, 0.4);
  }
  
  .color18:hover {
    color: #8b008b; /* Dark Magenta */
    box-shadow: 0 0 10px rgba(139, 0, 139, 0.8),
                0 0 20px rgba(139, 0, 139, 0.6),
                0 0 30px rgba(139, 0, 139, 0.4);
  }
  
  .color19:hover {
    color: #ffa500; /* Orange */
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8),
                0 0 20px rgba(255, 165, 0, 0.6),
                0 0 30px rgba(255, 165, 0, 0.4);
  }
  
  .color20:hover {
    color: #9932cc; /* Dark Orchid */
    box-shadow: 0 0 10px rgba(153, 50, 204, 0.8),
                0 0 20px rgba(153, 50, 204, 0.6),
                0 0 30px rgba(153, 50, 204, 0.4);
  }
  /* Additional CSS with 20 more class names and hover effects */
.color21:hover {
  color: #4b0082; /* Indigo */
  box-shadow: 0 0 10px rgba(75, 0, 130, 0.8),
              0 0 20px rgba(75, 0, 130, 0.6),
              0 0 30px rgba(75, 0, 130, 0.4);
}

.color22:hover {
  color: #00ff00; /* Lime */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8),
              0 0 20px rgba(0, 255, 0, 0.6),
              0 0 30px rgba(0, 255, 0, 0.4);
}

.color23:hover {
  color: #8b4513; /* Saddle Brown */
  box-shadow: 0 0 10px rgba(139, 69, 19, 0.8),
              0 0 20px rgba(139, 69, 19, 0.6),
              0 0 30px rgba(139, 69, 19, 0.4);
}

.color24:hover {
  color: #9932cc; /* Dark Orchid */
  box-shadow: 0 0 10px rgba(153, 50, 204, 0.8),
              0 0 20px rgba(153, 50, 204, 0.6),
              0 0 30px rgba(153, 50, 204, 0.4);
}

.color25:hover {
  color: #00ced1; /* Dark Turquoise */
  box-shadow: 0 0 10px rgba(0, 206, 209, 0.8),
              0 0 20px rgba(0, 206, 209, 0.6),
              0 0 30px rgba(0, 206, 209, 0.4);
}

.color26:hover {
  color: #9932cc; /* Dark Orchid */
  box-shadow: 0 0 10px rgba(153, 50, 204, 0.8),
              0 0 20px rgba(153, 50, 204, 0.6),
              0 0 30px rgba(153, 50, 204, 0.4);
}

.color27:hover {
  color: #20b2aa; /* Light Sea Green */
  box-shadow: 0 0 10px rgba(32, 178, 170, 0.8),
              0 0 20px rgba(32, 178, 170, 0.6),
              0 0 30px rgba(32, 178, 170, 0.4);
}

.color28:hover {
  color: #2e8b57; /* Sea Green */
  box-shadow: 0 0 10px rgba(46, 139, 87, 0.8),
              0 0 20px rgba(46, 139, 87, 0.6),
              0 0 30px rgba(46, 139, 87, 0.4);
}

.color29:hover {
  color: #b22222; /* Fire Brick */
  box-shadow: 0 0 10px rgba(178, 34, 34, 0.8),
              0 0 20px rgba(178, 34, 34, 0.6),
              0 0 30px rgba(178, 34, 34, 0.4);
}

.color30:hover {
  color: #6a5acd; /* Slate Blue */
  box-shadow: 0 0 10px rgba(106, 90, 205, 0.8),
              0 0 20px rgba(106, 90, 205, 0.6),
              0 0 30px rgba(106, 90, 205, 0.4);
}

.color31:hover {
  color: #da70d6; /* Orchid */
  box-shadow: 0 0 10px rgba(218, 112, 214, 0.8),
              0 0 20px rgba(218, 112, 214, 0.6),
              0 0 30px rgba(218, 112, 214, 0.4);
}

.color32:hover {
  color: #4169e1; /* Royal Blue */
  box-shadow: 0 0 10px rgba(65, 105, 225, 0.8),
              0 0 20px rgba(65, 105, 225, 0.6),
              0 0 30px rgba(65, 105, 225, 0.4);
}

.color33:hover {
  color: #6b8e23; /* Olive Drab */
  box-shadow: 0 0 10px rgba(107, 142, 35, 0.8),
              0 0 20px rgba(107, 142, 35, 0.6),
              0 0 30px rgba(107, 142, 35, 0.4);
}

.color34:hover {
  color: #4682b4; /* Steel Blue */
  box-shadow: 0 0 10px rgba(70, 130, 180, 0.8),
              0 0 20px rgba(70, 130, 180, 0.6),
              0 0 30px rgba(70, 130, 180, 0.4);
}

.color35:hover {
  color: #ffa07a; /* Light Salmon */
  box-shadow: 0 0 10px rgba(255, 160, 122, 0.8),
              0 0 20px rgba(255, 160, 122, 0.6),
              0 0 30px rgba(255, 160, 122, 0.4);
}

.color36:hover {
  color: #800080; /* Purple */
  box-shadow: 0 0 10px rgba(128, 0, 128, 0.8),
              0 0 20px rgba(128, 0, 128, 0.6),
              0 0 30px rgba(128, 0, 128, 0.4);
}

.color37:hover {
  color: #8b0000; /* Dark Red */
  box-shadow: 0 0 10px rgba(139, 0, 0, 0.8),
              0 0 20px rgba(139, 0, 0, 0.6),
              0 0 30px rgba(139, 0, 0, 0.4);
}

.color38:hover {
  color: #9932cc; /* Dark Orchid */
  box-shadow: 0 0 10px rgba(153, 50, 204, 0.8),
              0 0 20px rgba(153, 50, 204, 0.6),
              0 0 30px rgba(153, 50, 204, 0.4);
}

.color39:hover {
  color: #483d8b; /* Dark Slate Blue */
  box-shadow: 0 0 10px rgba(72, 61, 139, 0.8),
              0 0 20px rgba(72, 61, 139, 0.6),
              0 0 30px rgba(72, 61, 139, 0.4);
}

.color40:hover {
  color: #8b4513; /* Saddle Brown */
  box-shadow: 0 0 10px rgba(139, 69, 19, 0.8),
              0 0 20px rgba(139, 69, 19, 0.6),
              0 0 30px rgba(139, 69, 19, 0.4);
}
  .logo-link:hover {
    color: #778DA9;
    color: #ffd700; /* Change text color on hover */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 
              0 0 20px rgba(255, 255, 255, 0.6), 
              0 0 30px rgba(255, 255, 255, 0.4); 
  }
  /* Additional CSS with 20 more class names and hover effects */
.color41:hover {
  color: #ff6347; /* Tomato */
  box-shadow: 0 0 10px rgba(255, 99, 71, 0.8),
              0 0 20px rgba(255, 99, 71, 0.6),
              0 0 30px rgba(255, 99, 71, 0.4);
}

.color42:hover {
  color: #9932cc; /* Dark Orchid */
  box-shadow: 0 0 10px rgba(153, 50, 204, 0.8),
              0 0 20px rgba(153, 50, 204, 0.6),
              0 0 30px rgba(153, 50, 204, 0.4);
}

.color43:hover {
  color: #4682b4; /* Steel Blue */
  box-shadow: 0 0 10px rgba(70, 130, 180, 0.8),
              0 0 20px rgba(70, 130, 180, 0.6),
              0 0 30px rgba(70, 130, 180, 0.4);
}

.color44:hover {
  color: #8b0000; /* Dark Red */
  box-shadow: 0 0 10px rgba(139, 0, 0, 0.8),
              0 0 20px rgba(139, 0, 0, 0.6),
              0 0 30px rgba(139, 0, 0, 0.4);
}

.color45:hover {
  color: #20b2aa; /* Light Sea Green */
  box-shadow: 0 0 10px rgba(32, 178, 170, 0.8),
              0 0 20px rgba(32, 178, 170, 0.6),
              0 0 30px rgba(32, 178, 170, 0.4);
}

.color46:hover {
  color: #800080; /* Purple */
  box-shadow: 0 0 10px rgba(128, 0, 128, 0.8),
              0 0 20px rgba(128, 0, 128, 0.6),
              0 0 30px rgba(128, 0, 128, 0.4);
}

.color47:hover {
  color: #008080; /* Teal */
  box-shadow: 0 0 10px rgba(0, 128, 128, 0.8),
              0 0 20px rgba(0, 128, 128, 0.6),
              0 0 30px rgba(0, 128, 128, 0.4);
}

.color48:hover {
  color: #ffa500; /* Orange */
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.8),
              0 0 20px rgba(255, 165, 0, 0.6),
              0 0 30px rgba(255, 165, 0, 0.4);
}

.color49:hover {
  color: #ff4500; /* Orange Red */
  box-shadow: 0 0 10px rgba(255, 69, 0, 0.8),
              0 0 20px rgba(255, 69, 0, 0.6),
              0 0 30px rgba(255, 69, 0, 0.4);
}

.color50:hover {
  color: #6a5acd; /* Slate Blue */
  box-shadow: 0 0 10px rgba(106, 90, 205, 0.8),
              0 0 20px rgba(106, 90, 205, 0.6),
              0 0 30px rgba(106, 90, 205, 0.4);
}

.color51:hover {
  color: #20b2aa; /* Light Sea Green */
  box-shadow: 0 0 10px rgba(32, 178, 170, 0.8),
              0 0 20px rgba(32, 178, 170, 0.6),
              0 0 30px rgba(32, 178, 170, 0.4);
}

.color52:hover {
  color: #008b8b; /* Dark Cyan */
  box-shadow: 0 0 10px rgba(0, 139, 139, 0.8),
              0 0 20px rgba(0, 139, 139, 0.6),
              0 0 30px rgba(0, 139, 139, 0.4);
}

.color53:hover {
  color: #ffa07a; /* Light Salmon */
  box-shadow: 0 0 10px rgba(255, 160, 122, 0.8),
              0 0 20px rgba(255, 160, 122, 0.6),
              0 0 30px rgba(255, 160, 122, 0.4);
}

.color54:hover {
  color: #ff1493; /* Deep Pink */
  box-shadow: 0 0 10px rgba(255, 20, 147, 0.8),
              0 0 20px rgba(255, 20, 147, 0.6),
              0 0 30px rgba(255, 20, 147, 0.4);
}

.color55:hover {
  color: #00ced1; /* Dark Turquoise */
  box-shadow: 0 0 10px rgba(0, 206, 209, 0.8),
              0 0 20px rgba(0, 206, 209, 0.6),
              0 0 30px rgba(0, 206, 209, 0.4);
}

.color56:hover {
  color: #ba55d3; /* Medium Orchid */
  box-shadow: 0 0 10px rgba(186, 85, 211, 0.8),
              0 0 20px rgba(186, 85, 211, 0.6),
              0 0 30px rgba(186, 85, 211, 0.4);
}

.color57:hover {
  color: #b22222; /* Fire Brick */
  box-shadow: 0 0 10px rgba(178, 34, 34, 0.8),
              0 0 20px rgba(178, 34, 34, 0.6),
              0 0 30px rgba(178, 34, 34, 0.4);
}

.color58:hover {
  color: #4169e1; /* Royal Blue */
  box-shadow: 0 0 10px rgba(65, 105, 225, 0.8),
              0 0 20px rgba(65, 105, 225, 0.6),
              0 0 30px rgba(65, 105, 225, 0.4);
}

.color59:hover {
  color: #8a2be2; /* Blue Violet */
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.8),
              0 0 20px rgba(138, 43, 226, 0.6),
              0 0 30px rgba(138, 43, 226, 0.4);
}

.color60:hover {
  color: #8b008b; /* Dark Magenta */
  box-shadow: 0 0 10px rgba(139, 0, 139, 0.8),
              0 0 20px rgba(139, 0, 139, 0.6),
              0 0 30px rgba(139, 0, 139, 0.4);
}
/* Additional CSS with 20 more class names and hover effects */
.color61:hover {
  color: #00bfff; /* Deep Sky Blue */
  box-shadow: 0 0 10px rgba(0, 191, 255, 0.8),
              0 0 20px rgba(0, 191, 255, 0.6),
              0 0 30px rgba(0, 191, 255, 0.4);
}

.color62:hover {
  color: #ffa07a; /* Light Salmon */
  box-shadow: 0 0 10px rgba(255, 160, 122, 0.8),
              0 0 20px rgba(255, 160, 122, 0.6),
              0 0 30px rgba(255, 160, 122, 0.4);
}

.color63:hover {
  color: #8b4513; /* Saddle Brown */
  box-shadow: 0 0 10px rgba(139, 69, 19, 0.8),
              0 0 20px rgba(139, 69, 19, 0.6),
              0 0 30px rgba(139, 69, 19, 0.4);
}

.color64:hover {
  color: #4682b4; /* Steel Blue */
  box-shadow: 0 0 10px rgba(70, 130, 180, 0.8),
              0 0 20px rgba(70, 130, 180, 0.6),
              0 0 30px rgba(70, 130, 180, 0.4);
}

.color65:hover {
  color: #ff8c00; /* Dark Orange */
  box-shadow: 0 0 10px rgba(255, 140, 0, 0.8),
              0 0 20px rgba(255, 140, 0, 0.6),
              0 0 30px rgba(255, 140, 0, 0.4);
}

.color66:hover {
  color: #32cd32; /* Lime Green */
  box-shadow: 0 0 10px rgba(50, 205, 50, 0.8),
              0 0 20px rgba(50, 205, 50, 0.6),
              0 0 30px rgba(50, 205, 50, 0.4);
}

.color67:hover {
  color: #800080; /* Purple */
  box-shadow: 0 0 10px rgba(128, 0, 128, 0.8),
              0 0 20px rgba(128, 0, 128, 0.6),
              0 0 30px rgba(128, 0, 128, 0.4);
}

.color68:hover {
  color: #ba55d3; /* Medium Orchid */
  box-shadow: 0 0 10px rgba(186, 85, 211, 0.8),
              0 0 20px rgba(186, 85, 211, 0.6),
              0 0 30px rgba(186, 85, 211, 0.4);
}

.color69:hover {
  color: #008080; /* Teal */
  box-shadow: 0 0 10px rgba(0, 128, 128, 0.8),
              0 0 20px rgba(0, 128, 128, 0.6),
              0 0 30px rgba(0, 128, 128, 0.4);
}

.color70:hover {
  color: #ff4500; /* Orange Red */
  box-shadow: 0 0 10px rgba(255, 69, 0, 0.8),
              0 0 20px rgba(255, 69, 0, 0.6),
              0 0 30px rgba(255, 69, 0, 0.4);
}

.color71:hover {
  color: #008b8b; /* Dark Cyan */
  box-shadow: 0 0 10px rgba(0, 139, 139, 0.8),
              0 0 20px rgba(0, 139, 139, 0.6),
              0 0 30px rgba(0, 139, 139, 0.4);
}

.color72:hover {
  color: #9932cc; /* Dark Orchid */
  box-shadow: 0 0 10px rgba(153, 50, 204, 0.8),
              0 0 20px rgba(153, 50, 204, 0.6),
              0 0 30px rgba(153, 50, 204, 0.4);
}

.color73:hover {
  color: #00ffff; /* Cyan */
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.8),
              0 0 20px rgba(0, 255, 255, 0.6),
              0 0 30px rgba(0, 255, 255, 0.4);
}

.color74:hover {
  color: #ffd700; /* Gold */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.8),
              0 0 20px rgba(255, 215, 0, 0.6),
              0 0 30px rgba(255, 215, 0, 0.4);
}

.color75:hover {
  color: #00ff00; /* Lime */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8),
              0 0 20px rgba(0, 255, 0, 0.6),
              0 0 30px rgba(0, 255, 0, 0.4);
}

.color76:hover {
  color: #00fa9a; /* Medium Spring Green */
  box-shadow: 0 0 10px rgba(0, 250, 154, 0.8),
              0 0 20px rgba(0, 250, 154, 0.6),
              0 0 30px rgba(0, 250, 154, 0.4);
}

.color77:hover {
  color: #9932cc; /* Dark Orchid */
  box-shadow: 0 0 10px rgba(153, 50, 204, 0.8),
              0 0 20px rgba(153, 50, 204, 0.6),
              0 0 30px rgba(153, 50, 204, 0.4);
}

.color78:hover {
  color: #ffa500; /* Orange */
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.8),
              0 0 20px rgba(255, 165, 0, 0.6),
              0 0 30px rgba(255, 165, 0, 0.4);
}

.color79:hover {
  color: #7cfc00; /* Lawn Green */
  box-shadow: 0 0 10px rgba(124, 252, 0, 0.8),
              0 0 20px rgba(124, 252, 0, 0.6),
              0 0 30px rgba(124, 252, 0, 0.4);
}


.color80:hover {
  color: #ff1493; /* Deep Pink */
  box-shadow: 0 0 10px rgba(255, 20, 147, 0.8),
              0 0 20px rgba(255, 20, 147, 0.6),
              0 0 30px rgba(255, 20, 147, 0.4);
}
/* Additional CSS with 30 more class names and hover effects */
.color111:hover {
  color: #ff6347; /* Tomato */
  box-shadow: 0 0 10px rgba(255, 99, 71, 0.8),
              0 0 20px rgba(255, 99, 71, 0.6),
              0 0 30px rgba(255, 99, 71, 0.4);
}

.color112:hover {
  color: #7b68ee; /* Medium Slate Blue */
  box-shadow: 0 0 10px rgba(123, 104, 238, 0.8),
              0 0 20px rgba(123, 104, 238, 0.6),
              0 0 30px rgba(123, 104, 238, 0.4);
}

.color113:hover {
  color: #00ff7f; /* Spring Green */
  box-shadow: 0 0 10px rgba(0, 255, 127, 0.8),
              0 0 20px rgba(0, 255, 127, 0.6),
              0 0 30px rgba(0, 255, 127, 0.4);
}

.color114:hover {
  color: #4682b4; /* Steel Blue */
  box-shadow: 0 0 10px rgba(70, 130, 180, 0.8),
              0 0 20px rgba(70, 130, 180, 0.6),
              0 0 30px rgba(70, 130, 180, 0.4);
}

.color115:hover {
  color: #ffd700; /* Gold */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.8),
              0 0 20px rgba(255, 215, 0, 0.6),
              0 0 30px rgba(255, 215, 0, 0.4);
}

.color116:hover {
  color: #dc143c; /* Crimson */
  box-shadow: 0 0 10px rgba(220, 20, 60, 0.8),
              0 0 20px rgba(220, 20, 60, 0.6),
              0 0 30px rgba(220, 20, 60, 0.4);
}

.color117:hover {
  color: #8a2be2; /* Blue Violet */
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.8),
              0 0 20px rgba(138, 43, 226, 0.6),
              0 0 30px rgba(138, 43, 226, 0.4);
}

.color118:hover {
  color: #5f9ea0; /* Cadet Blue */
  box-shadow: 0 0 10px rgba(95, 158, 160, 0.8),
              0 0 20px rgba(95, 158, 160, 0.6),
              0 0 30px rgba(95, 158, 160, 0.4);
}

.color119:hover {
  color: #d2691e; /* Chocolate */
  box-shadow: 0 0 10px rgba(210, 105, 30, 0.8),
              0 0 20px rgba(210, 105, 30, 0.6),
              0 0 30px rgba(210, 105, 30, 0.4);
}

.color120:hover {
  color: #1e90ff; /* Dodger Blue */
  box-shadow: 0 0 10px rgba(30, 144, 255, 0.8),
              0 0 20px rgba(30, 144, 255, 0.6),
              0 0 30px rgba(30, 144, 255, 0.4);
}

.color121:hover {
  color: #32cd32; /* Lime Green */
  box-shadow: 0 0 10px rgba(50, 205, 50, 0.8),
              0 0 20px rgba(50, 205, 50, 0.6),
              0 0 30px rgba(50, 205, 50, 0.4);
}

.color122:hover {
  color: #ff69b4; /* Hot Pink */
  box-shadow: 0 0 10px rgba(255, 105, 180, 0.8),
              0 0 20px rgba(255, 105, 180, 0.6),
              0 0 30px rgba(255, 105, 180, 0.4);
}

.color123:hover {
  color: #ff6347; /* Tomato */
  box-shadow: 0 0 10px rgba(255, 99, 71, 0.8),
              0 0 20px rgba(255, 99, 71, 0.6),
              0 0 30px rgba(255, 99, 71, 0.4);
}

.color124:hover {
  color: #7b68ee; /* Medium Slate Blue */
  box-shadow: 0 0 10px rgba(123, 104, 238, 0.8),
              0 0 20px rgba(123, 104, 238, 0.6),
              0 0 30px rgba(123, 104, 238, 0.4);
}

.color125:hover {
  color: #00ff7f; /* Spring Green */
  box-shadow: 0 0 10px rgba(0, 255, 127, 0.8),
              0 0 20px rgba(0, 255, 127, 0.6),
              0 0 30px rgba(0, 255, 127, 0.4);
}

.color126:hover {
  color: #4682b4; /* Steel Blue */
  box-shadow: 0 0 10px rgba(70, 130, 180, 0.8),
              0 0 20px rgba(70, 130, 180, 0.6),
              0 0 30px rgba(70, 130, 180, 0.4);
}

.color127:hover {
  color: #ffd700; /* Gold */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.8),
              0 0 20px rgba(255, 215, 0, 0.6),
              0 0 30px rgba(255, 215, 0, 0.4);
}

.color128:hover {
  color: #dc143c; /* Crimson */
  box-shadow: 0 0 10px rgba(220, 20, 60, 0.8),
              0 0 20px rgba(220, 20, 60, 0.6),
              0 0 30px rgba(220, 20, 60, 0.4);
}

.color129:hover {
  color: #8a2be2; /* Blue Violet */
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.8),
              0 0 20px rgba(138, 43, 226, 0.6),
              0 0 30px rgba(138, 43, 226, 0.4);
}

.color130:hover {
  color: #5f9ea0; /* Cadet Blue */
  box-shadow: 0 0 10px rgba(95, 158, 160, 0.8),
              0 0 20px rgba(95, 158, 160, 0.6),
              0 0 30px rgba(95, 158, 160, 0.4);
}

.color131:hover {
  color: #d2691e; /* Chocolate */
  box-shadow: 0 0 10px rgba(210, 105, 30, 0.8),
              0 0 20px rgba(210, 105, 30, 0.6),
              0 0 30px rgba(210, 105, 30, 0.4);
}

.color132:hover {
  color: #1e90ff; /* Dodger Blue */
  box-shadow: 0 0 10px rgba(30, 144, 255, 0.8),
              0 0 20px rgba(30, 144, 255, 0.6),
              0 0 30px rgba(30, 144, 255, 0.4);
}

/* Additional CSS with 20 more class names and hover effects */
.color141:hover {
  color: #800000; /* Maroon */
  box-shadow: 0 0 10px rgba(128, 0, 0, 0.8),
              0 0 20px rgba(128, 0, 0, 0.6),
              0 0 30px rgba(128, 0, 0, 0.4);
}

.color142:hover {
  color: #6a5acd; /* Slate Blue */
  box-shadow: 0 0 10px rgba(106, 90, 205, 0.8),
              0 0 20px rgba(106, 90, 205, 0.6),
              0 0 30px rgba(106, 90, 205, 0.4);
}

.color143:hover {
  color: #ff4500; /* Orange Red */
  box-shadow: 0 0 10px rgba(255, 69, 0, 0.8),
              0 0 20px rgba(255, 69, 0, 0.6),
              0 0 30px rgba(255, 69, 0, 0.4);
}

.color144:hover {
  color: #2e8b57; /* Sea Green */
  box-shadow: 0 0 10px rgba(46, 139, 87, 0.8),
              0 0 20px rgba(46, 139, 87, 0.6),
              0 0 30px rgba(46, 139, 87, 0.4);
}

.color145:hover {
  color: #ffa500; /* Orange */
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.8),
              0 0 20px rgba(255, 165, 0, 0.6),
              0 0 30px rgba(255, 165, 0, 0.4);
}

.color146:hover {
  color: #9400d3; /* Dark Violet */
  box-shadow: 0 0 10px rgba(148, 0, 211, 0.8),
              0 0 20px rgba(148, 0, 211, 0.6),
              0 0 30px rgba(148, 0, 211, 0.4);
}

.color147:hover {
  color: #ff1493; /* Deep Pink */
  box-shadow: 0 0 10px rgba(255, 20, 147, 0.8),
              0 0 20px rgba(255, 20, 147, 0.6),
              0 0 30px rgba(255, 20, 147, 0.4);
}

.color148:hover {
  color: #00ff00; /* Lime */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8),
              0 0 20px rgba(0, 255, 0, 0.6),
              0 0 30px rgba(0, 255, 0, 0.4);
}

.color149:hover {
  color: #ff00ff; /* Magenta */
  box-shadow: 0 0 10px rgba(255, 0, 255, 0.8),
              0 0 20px rgba(255, 0, 255, 0.6),
              0 0 30px rgba(255, 0, 255, 0.4);
}

.color150:hover {
  color: #ffd700; /* Gold */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.8),
              0 0 20px rgba(255, 215, 0, 0.6),
              0 0 30px rgba(255, 215, 0, 0.4);
}

.color151:hover {
  color: #8b0000; /* Dark Red */
  box-shadow: 0 0 10px rgba(139, 0, 0, 0.8),
              0 0 20px rgba(139, 0, 0, 0.6),
              0 0 30px rgba(139, 0, 0, 0.4);
}

.color152:hover {
  color: #e9967a; /* Dark Salmon */
  box-shadow: 0 0 10px rgba(233, 150, 122, 0.8),
              0 0 20px rgba(233, 150, 122, 0.6),
              0 0 30px rgba(233, 150, 122, 0.4);
}

.color153:hover {
  color: #00bfff; /* Deep Sky Blue */
  box-shadow: 0 0 10px rgba(0, 191, 255, 0.8),
              0 0 20px rgba(0, 191, 255, 0.6),
              0 0 30px rgba(0, 191, 255, 0.4);
}

.color154:hover {
  color: #8a2be2; /* Blue Violet */
  box-shadow: 0 0 10px rgba(138, 43, 226, 0.8),
              0 0 20px rgba(138, 43, 226, 0.6),
              0 0 30px rgba(138, 43, 226, 0.4);
}

.color155:hover {
  color: #556b2f; /* Dark Olive Green */
  box-shadow: 0 0 10px rgba(85, 107, 47, 0.8),
              0 0 20px rgba(85, 107, 47, 0.6),
              0 0 30px rgba(85, 107, 47, 0.4);
}

.color156:hover {
  color: #ff69b4; /* Hot Pink */
  box-shadow: 0 0 10px rgba(255, 105, 180, 0.8),
              0 0 20px rgba(255, 105, 180, 0.6),
              0 0 30px rgba(255, 105, 180, 0.4);
}

.color157:hover {
  color: #cd5c5c; /* Indian Red */
  box-shadow: 0 0 10px rgba(205, 92, 92, 0.8),
              0 0 20px rgba(205, 92, 92, 0.6),
              0 0 30px rgba(205, 92, 92, 0.4);
}

.color158:hover {
  color: #4b0082; /* Indigo */
  box-shadow: 0 0 10px rgba(75, 0, 130, 0.8),
              0 0 20px rgba(75, 0, 130, 0.6),
              0 0 30px rgba(75, 0, 130, 0.4);
}

.color159:hover {
  color: #20b2aa; /* Light Sea Green */
  box-shadow: 0 0 10px rgba(32, 178, 170, 0.8),
              0 0 20px rgba(32, 178, 170, 0.6),
              0 0 30px rgba(32, 178, 170, 0.4);
}

.color160:hover {
  color: #ff4500; /* Orange Red */
  box-shadow: 0 0 10px rgba(255, 69, 0, 0.8),
              0 0 20px rgba(255, 69, 0, 0.6),
              0 0 30px rgba(255, 69, 0, 0.4);
}

  /* .dropdown-content:hover{
    background-color: #df3415;

  } */
  
  .navbar-toggle {
    display: none;
    cursor: pointer;
  }
  
  .navbar-toggle i {
    font-size: 1.5rem;
  }
  
  @media screen and (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      background-color: #778DA9;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      padding: 20px 0;
    }
  
    .navbar-links.active {
      display: flex;
    }
  
    .navbar-toggle {
      display: block;
    }
  }
  .dropdown {
    position: relative;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #778DA9;
    min-width: 160px;
    border-radius: 22px;
    z-index: 1;
    text-align: left;
  }
  
  .dropdown-content a {
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
}
  .dropdown-content:hover{

  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .navbar-logo {
    position: relative;
  }
  
  .navbar-logo .dropdown-content {
    display: none;
    position: absolute;
    background-color: #1B263B;;
    min-width: 160px;
    z-index: 1;
    top: 100%;
    left: 0;
  }
  
  .navbar-logo.active .dropdown-content {
    display: block;
  }
  
  .navbar-logo a {
    color: #fff;
    text-decoration: none;
  }
  
  .navbar-logo:hover .dropdown-content {
    display: block;
  }
  