/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
    background-color: #f0f4f8;
    color: #333;
  }
  
  h1, h2 {
    text-align: center;
    color: #1a202c;
    margin-bottom: 20px;
  } */
  
  .shopping-cart-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .product-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
  
  .product-price {
    font-size: 1.1em;
    color: #4caf50;
    margin: 10px 0;
  }
  
  .add-to-cart-btn {
    background-color: #1a73e8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .add-to-cart-btn:hover {
    background-color: #155ab0;
    transform: translateY(-3px);
  }
  
  .shopping-cart ul {
    list-style-type: none;
    padding: 0;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .quantity-controls button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .remove-btn {
    background-color: #e53e3e;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s;
  }
  
  .remove-btn:hover {
    background-color: #c53030;
  }
  
  .cart-summary {
    text-align: right;
    margin-top: 20px;
    font-size: 1.2em;
  }
  
  .total-price {
    font-weight: bold;
    color: #1a202c;
  }
  
  .empty-cart-message {
    text-align: center;
    font-size: 1.1em;
    color: #e53e3e;
  }
  
  .new-product-form {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .new-product-form input {
    padding: 10px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .add-product-btn {
    background-color: #48bb78;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .add-product-btn:hover {
    background-color: #38a169;
  }
  