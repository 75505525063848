.bubble-cont {
    width: 700px;
    border: 4px solid transparent;
    padding: 20px 30px;
    margin: 40px auto;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    background: linear-gradient(
        135deg,
        rgba(64, 91, 119, 0.8),
        rgba(40, 64, 86, 0.8)
    ),
    url('https://www.transparenttextures.com/patterns/asfalt-dark.png'); /* Subtle texture */
    background-size: cover; /* Ensure the image covers the entire container */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-position: center; /* Center the background image */
    margin: 0 auto; /* Center the container horizontally */
}
ol{
    color: white !important;
}
li{
    color: white !important;
}
pre{
    color: white !important;
}
.bubble-show{
    width:660px;
    border: 4px solid transparent;
    border-radius: 13px;
    margin: 0 auto;
    padding:20px;
    box-sizing: border-box; /* Include padding and border in the box’s total width and height */
    overflow: hidden; /* Hide overflowed content */
    word-wrap: break-word; /* Break long words onto the next line */
    text-align: left;
}

@media screen and (max-width: 640px) {
    .bubble-cont {
        width: 300px;
        border: 4px solid transparent;
        padding: 20px 30px;
        margin: 40px auto; /* Center the container horizontally */
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    }
    .bubble-show{
        width:100%;
        border: 4px solid transparent;
        border-radius: 13px;
        margin: 0 auto;
        padding:15px;
        box-sizing: border-box; /* Include padding and border in the box’s total width and height */
        overflow: hidden; /* Hide overflowed content */
        word-wrap: break-word; /* Break long words onto the next line */
        text-align: left;
    }
 
    
    .merge-container, .quick-cont, .quick-show, .bubble-show , .insertion-show, .insertion-cont, .d3-cont {
        background: linear-gradient(
            135deg,
            rgba(64, 91, 119, 0.8),
            rgba(40, 64, 86, 0.8)
        ),
        url('https://www.transparenttextures.com/patterns/asfalt-dark.png'); /* Subtle texture */
        background-size: cover; /* Ensure the image covers the entire container */
        background-repeat: no-repeat; /* Prevent the image from repeating */
        background-position: center; /* Center the background image */
    }
  }

  @media screen and (max-width: 896px) {
    .bubble-cont {
        width: 300px;
        border: 4px solid transparent;
        padding: 20px 30px;
        margin: 40px auto; /* Center the container horizontally */
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    }
 
    
    .merge-container, .quick-cont, .quick-show, .bubble-show , .insertion-show, .insertion-cont, .d3-cont {
        background: linear-gradient(
            135deg,
            rgba(64, 91, 119, 0.8),
            rgba(40, 64, 86, 0.8)
        ),
        url('https://www.transparenttextures.com/patterns/asfalt-dark.png'); /* Subtle texture */
        background-size: cover; /* Ensure the image covers the entire container */
        background-repeat: no-repeat; /* Prevent the image from repeating */
        background-position: center; /* Center the background image */
    }
  }