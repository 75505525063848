/* Navbar Styling */
.navbar {
    width: 100%;
    /* background-color: #333;
    overflow: hidden; */
  }
  .d3-cont{
    width: 700px;
    width: 700px;
    border: 4px solid transparent;
    padding: 20px 30px;
    margin: 40px auto;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
  @media only screen  and (max-width: 480) {
    .navbar {
        background-color: #0D1B2A;
        /* background-color: #696969; */
        color: #FFFFFF;
        /* color: #fff; */
        padding: 10px 0;
        width: 100%;
        /* position: fixed; */
      }
      .d3-cont{
        width: 300px;
        width: 700px;
        border: 4px solid transparent;
        padding: 20px 30px;
        margin: 40px auto;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
      }
  }
  
  /* .navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
   */
  /* Responsive Navbar: Adjust layout for small screens */
  @media screen and (max-width: 640px) {
    .d3-cont {
        width: 300px;
        border: 4px solid transparent;
        padding: 20px 30px;
        margin: 40px auto; /* Center the container horizontally */
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    }
    .quick-show {
        width: 300px;
        border: 4px solid transparent;
        border-radius: 13px;
        margin: 0 auto; /* Center the container horizontally */
    }
    
    .merge-container, .quick-cont, .quick-show, .bubble-show , .insertion-show, .insertion-cont, .d3-cont {
        background: linear-gradient(
            135deg,
            rgba(64, 91, 119, 0.8),
            rgba(40, 64, 86, 0.8)
        ),
        url('https://www.transparenttextures.com/patterns/asfalt-dark.png'); /* Subtle texture */
        background-size: cover; /* Ensure the image covers the entire container */
        background-repeat: no-repeat; /* Prevent the image from repeating */
        background-position: center; /* Center the background image */
    }
  }
  
  /* Chart Styling */
  .chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    .chart-container {
      width: 95%; /* Slight padding for mobile view */
    }
    .navbar {
        width: 100%;
        /* background-color: #333;
        overflow: hidden; */
      }
  }
  
  @media screen and (max-width: 480px) {
    .chart-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 20px;
      }
    .navbar {
        width: 100%;
        /* background-color: #333;
        overflow: hidden; */
      }
  }
  