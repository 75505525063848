.container {
    max-width: 400px;
    margin: 0 auto;
    /* border: 3px solid; */
}

h2 {
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-top: 10px;
}

input,
textarea {
    padding: 5px;
    margin-top: 5px;
}

button {
    margin-top: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.container {
    max-width: 400px;
    margin: 0 auto;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

h2 {
    text-align: center;
    animation: slideInDown 4s ease-in-out;
    -webkit-animation: slideInDown 4s ease-in-out;
}

@keyframes slideInDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

form {
    display: flex;
    flex-direction: column;
    animation: slideInUp 8s ease-in-out;
    -webkit-animation: slideInUp 8s ease-in-out;
}

@keyframes slideInUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

label {
    margin-top: 10px;
    animation: fadeIn 4s ease-in-out;
    -webkit-animation: fadeIn 4s ease-in-out;
}

input,
textarea {
    padding: 5px;
    margin-top: 5px;
    animation: fadeIn 5s ease-in-out;
    -webkit-animation: fadeIn 5s ease-in-out;
}

button {
    margin-top: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    cursor: pointer;
    animation: fadeIn 1s ease-in-out;
}

button:hover {
    background-color: #45a049;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}