a {
    text-decoration: none;
    color: #007185;
}

a:hover {
    text-decoration: underline;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
}

.port-5 {
    background-color: #0D1B2A;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    max-width: 350px;
    height: auto;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    -moz-transition: transform 0.2s;
    -ms-transition: transform 0.2s;
    -o-transition: transform 0.2s;
}

.port-5:hover {
    transform: scale(1.05);
}

.pfp1 {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

/* h1 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
}

h2 {
    font-size: 14px;
    color: #555;
    margin: 10px 0;
    line-height: 1.4;
} */

/* Dropdown Section */
.dropdown-section {
    margin: 15px 0;
    text-align: left;
}

.dropdown-section label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.dropdown-section select {
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* About Section */
.about-section {
    margin: 20px 0;
}

.about-section h3 {
    font-size: 16px;
    font-weight: bold;
    color: #007185;
    margin-bottom: 10px;
}

.about-section ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
}

.about-section li {
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
}

/* Button Styles */
button.color71, .color71 {
    background-color: #ffa41c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
    display: inline-block;
    text-align: center;
}

button.color71:hover, .color71:hover {
    background-color: #e08a00;
}

@media screen and (max-width: 768px) {
    .product-list {
        flex-direction: column;
        align-items: center;
    }

    .port-1 {
        max-width: 90%;
    }
}