/* src/App.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .container {
    padding: 20px;
    color: #333;
  }
  
  .video-container {
    margin-top: 20px;
  }
  
  .video {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
  
  /* Media Queries */
  @media (max-width: 1200px) {
    .container {
      padding: 15px;
    }
  }
  
  @media (max-width: 992px) {
    .container {
      padding: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 5px;
    }
    .video {
      width: 100%;
      max-width: 500px;
    }
  }
  
  @media (max-width: 576px) {
    .container {
      padding: 2px;
    }
    .video {
      width: 100%;
      max-width: 400px;
    }
  }
  
  @media (max-width: 400px) {
    .video {
      width: 100%;
      max-width: 300px;
    }
  }
  