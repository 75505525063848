General body styles
body, html {
    margin: 0;
    padding: 0;
    height: 100%; /* Ensure the height of body and html is 100% */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Galaxy-specific wrapper */
.galaxy-wrapper {
    position: relative;
    width: 100%;
    height: 100vh; /* Full height of the viewport */
    overflow: hidden; /* Hide overflow inside this section */
}

/* Canvas should take up the full width and height */
#renderCanvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
}

/* Button styling remains the same */
button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}
