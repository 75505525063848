.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
  
    z-index: -1;
}
p{
    font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;

}
h2{
    font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;
}
h3{
    font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;
}
h1{
    font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;
}
ol{
    font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;
}
li{
    font-family: "Jersey 15", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}
/* .socio{
    display: flexbox;
    justify-content: space-between !important;
}
.intro{
    width: 100%;
    height:50%;
    border: 3px solid;
    background-color: white;
    color:black !important;
    text-align: left;
    padding:20px 20px 20px 20px;
    margin: 20px;
}
.intro2{
    width: 100%;
    height:50%;
    border: 3px solid;
    background-color: white;
   padding:20px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   flex-grow: 6;
   flex-wrap: wrap
} */
.main-container1{
    /* width: 100%;
    height:50%;
    border: 3px solid;
    background-color: white; */
    /* display:flex;
flex-direction: row;
justify-content: space-between;
padding:20px;
flex-wrap: wrap;
flex-grow: 6; */
    /* width:100%;
    height:70%; */
    /* background-color:white; */
    /* display:flex !important; */
/* flex-direction: row; */
/* justify-content: space-between; */
/* padding:20px; */
/* flex-wrap: wrap; */
/* flex-grow: 6; */
}
/* .container{
    background-color: aqua;
}
.port-1{
    width: 400px;
    height:600px;
    border: 3px solid transparent;
    padding: 20px 20px;
    background-color:#0874DB;
    border-radius:12px; 
    color: white;
    -webkit-border-radius:12%;
    -moz-border-radius:12%;
    -ms-border-radius:12%;
    -o-border-radius:12%;
}
.port-2{
    width: 400px;
    height:600px;
    border: 3px solid transparent;
    padding: 20px 20px;
    border-radius:12px; 
    color: white;
    background-color: #E91524;
}
.port-3{
    width: 400px;
    height:600px;
    border: 3px solid transparent;
    padding: 20px 20px;
    background-color: #263377;
    color: white;
    border-radius:12px; 
    -webkit-border-radius:12 ;
    -moz-border-radius:12 ;
    -ms-border-radius:12 ;
    -o-border-radius:12 ;
} */
/* .port-4{
    width: 400px;
    height:400px;
    border: 3px solid transparent;
    padding: 20px 20px;
    background-color: rgb(167, 148, 162);
    border-radius:12px;
    -webkit-border-radius:12px;
    -moz-border-radius:12px;
    -ms-border-radius:12px;
    -o-border-radius:12px;
} */
/* .main-container{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding:20px;
    flex-wrap: wrap;
    flex-grow: 6;
    
    }

    .img1{
        width:400px;
        height: 600px;
        border-radius: 12px;
        
    } */
    @media screen and (max-device-width: 480px) 
    and (orientation: portrait) {
  
}

 