/* QuantumPlan.css */

.quantum-plan {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .quantum-plan h1, .quantum-plan h2, .quantum-plan h3 {
    color: #0056b3;
  }
  
  .quantum-plan h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .quantum-plan h2 {
    font-size: 2em;
    margin-top: 30px;
    border-bottom: 2px solid #0056b3;
    padding-bottom: 10px;
  }
  
  .quantum-plan h3 {
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .quantum-plan p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .quantum-plan ul {
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .quantum-plan ul li {
    margin-bottom: 10px;
  }
  
  .quantum-plan ul ul {
    margin-top: 5px;
  }
  
  .quantum-plan table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .quantum-plan table th, .quantum-plan table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .quantum-plan table th {
    background-color: #f4f4f4;
    color: #0056b3;
    font-size: 1.1em;
  }
  
  .quantum-plan table td {
    font-size: 1em;
  }
  
  .quantum-plan table thead {
    background-color: #e9ecef;
  }
  
  .quantum-plan table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .quantum-plan table tbody tr:hover {
    background-color: #f1f1f1;
  } 
  

         /* QuantumPlan.css */

/* Basic container styling */
/* .quantum-plan {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  } */
  
  /* Heading styles */
  /* .quantum-plan h1 {
    font-size: 2.2em;
    color: #007bff;
    margin-bottom: 20px;
  } */
  
  /* .quantum-plan h2 {
    font-size: 1.8em;
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
    margin-top: 30px;
  } */
  
  /* .quantum-plan h3 {
    font-size: 1.4em;
    color: #333;
    margin-top: 20px;
  } */
  
  /* Paragraph styling */
  /* .quantum-plan p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 20px;
  } */
  
  /* List styling */
  /* .quantum-plan ul {
    margin: 20px 0;
    padding-left: 20px;
  } */
  
  /* .quantum-plan ul li {
    margin-bottom: 10px;
    line-height: 1.6;
  } */
  
  /* Table styling */
  /* .quantum-plan table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  } */
  
  /* .quantum-plan table th, .quantum-plan table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .quantum-plan table th {
    background-color: #f8f9fa;
    color: #007bff;
    font-size: 1.1em;
  }
  
  .quantum-plan table td {
    font-size: 0.9em;
  }
  
  .quantum-plan table thead {
    background-color: #e9ecef;
  }
  
  .quantum-plan table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .quantum-plan table tbody tr:hover {
    background-color: #f1f1f1;
  }
   */