/* .tokenization-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .tokenization-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .token {
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  .token-index {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .explanation-box {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #0d0d0d;
  }
  
  @media (max-width: 640px) {
    .tokenization-container {
      padding: 10px;
    }
  
    .tokenization-box {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .token {
      width: 100%;
      margin-bottom: 10px;
    }
  }
   */


   /* Tokenization.css */

/* Base styles for the container */
.tokenization-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background: linear-gradient(135deg, #f0f0f0, #ffffff);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Typography styles */
h4 {
  color: #333;
  margin-bottom: 15px;
}

h3 {
  color: #007acc;
  margin-bottom: 10px;
}

h6 {
  color: #555;
  margin-bottom: 20px;
}

/* Button styles */
.MuiButton-contained {
  background: #007acc;
  color: #fff;
  &:hover {
    background: #005fa3;
  }
}

.MuiButton-outlined {
  border-color: #007acc;
  color: #007acc;
  &:hover {
    background: #e6f0ff;
  }
}

/* TextField styles */
.MuiTextField-root {
  margin-bottom: 20px;
  width: 100%;
}

/* Tokenization box */
.tokenization-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Token styles */
.token {
  display: flex;
  align-items: center;
  background: #007acc;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.token:hover {
  transform: scale(1.05);
}

.token-index {
  font-weight: bold;
  margin-right: 8px;
}

.token-value {
  font-weight: normal;
}

/* Explanation box */
.explanation-box {
  padding: 20px;
  background: #e6f7ff;
  border: 1px solid #b3d9ff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bubble-show {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .tokenization-container {
    padding: 15px;
    width: 100%;
  }

  .tokenization-box {
    flex-direction: column;
  }
}
