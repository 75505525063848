
/* .App-ola{
  width: 100% !important;
  height:100% !important;
} */

/* 
body{
  background-image: url('assets/02.jpeg');
} */


.socio{
    padding:20px;
    column-gap:20px;
    /* gap: 30px; */

    /* display: flex; */
    justify-content: space-between !important;
}
/* .storyline{
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    background-color: #b73f3f;
    color: white;
    padding: 20px 20px;
    border-radius:12px;
    -webkit-border-radius:12px;
    -moz-border-radius:12px;
    -ms-border-radius:12px;
    -o-border-radius:12px;

} */

.intro{
    width: auto !important;
    height:auto;
    border: 3px solid  transparent !important;
    border-radius:
    12px;
    background-color: #333;
    color:rgb(243, 240, 240) !important;
    text-align: left;
    padding:20px 20px 20px 20px;
    margin: 20px;
   
}
/* .intro4{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-grow: 4;
  flex-wrap: wrap;
  row-gap: 45px;
  column-gap: 20px;
} */
 /* .intro5{
width: 600px;
border: 4px solid black;
display: flex;
 } */
/* .intro5{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-grow: 4;
  flex-wrap: wrap;
  row-gap: 45px;
  column-gap: 20px;
} */
.intro2{
    width: auto;
    height:auto;
    border: 3px solid transparent !important;
    /* background-color: white; */
   padding:20px;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   flex-grow: 4;
   flex-wrap: wrap;
   row-gap: 45px;
   column-gap: 20px;
}
.fname:hover{
    background-color: rgb(68, 66, 54);

}
.main-container1{
    width: 100%;
    height:50%;
    border: 3px solid;
    background-color: white;
    /* display:flex;
flex-direction: row;
justify-content: space-between;
padding:20px;
flex-wrap: wrap;
flex-grow: 6; */
    /* width:100%;
    height:70%; */
    /* background-color:white; */
    /* display:flex !important; */
/* flex-direction: row; */
/* justify-content: space-between; */
/* padding:20px; */
/* flex-wrap: wrap; */
/* flex-grow: 6; */
}
/* .text-container{
    padding:160px;
    display:flex;
} */
.container{
    background-color: rgb(73, 139, 139);
    /* background-color: #3201C0; */
}
.App-ola{
            width:470px;
            height:470px;
            padding:100px;
            border-radius: 20% ;
          
}
.port-1{
    width: 400px;
    height:450px;
    border: 3px solid transparent;
    padding: 20px 20px;
    background-color:#1B263B;
    /* background-color:#F5FFFA; */
    /* background-color: white; */
    border-radius:12px; 
    color: #FFFFFF;
 
}
.port-2{
    width: 400px;
    height:450px;
    border: 3px solid transparent;
    padding: 20px 20px;
    border-radius:12px; 
    color: white;
    background-color: #1B263B;
    /* background-color: white; */
    /* color: black; */
}
.port-3{
    width: 400px;
    height:450px;
    border: 3px solid transparent;
    padding: 20px 20px;
    background-color: #1B263B;
    color: black;
    /* background-color: white; */
    color: white;
    border-radius:12px; 
    -webkit-border-radius:12 ;
    -moz-border-radius:12 ;
    -ms-border-radius:12 ;
    -o-border-radius:12 ;
}
.port-4{
    width: 400px;
    height:450px;
    border: 3px solid transparent;
    padding: 20px 20px;
    background-color: #1B263B;
    /* background-color: rgb(172, 164, 164); */
    /* color: black; */
    border-radius:12px;
    -webkit-border-radius:12px;
    -moz-border-radius:12px;
    -ms-border-radius:12px;
    -o-border-radius:12px;
}
.main-container{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding:20px;
    flex-wrap: wrap;
    flex-grow: 6;
    
    }

    .img1{
        width:400px;
        height: 600px;
        border-radius: 12px;
        
    }
    h2{
        color:white
        
    }
    h1 {
        color: white;
    }
    p{
      color: white;
    }
    .projects-title{
        display: flex;
    }
    h1{
        color: white;
        .text-container {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 20px;
            align-items: center;
        }
    }
    h2 {
        color: white;
        text-shadow: 0 0 40px 0 0 40px rgba(28, 31, 28, 0.8);
    }
    h3{
      color: white;
        text-shadow: 0 0 40px rgba(28, 31, 28, 0.8);
    }
    /* .resume-container{
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 20px;
        align-items: center;
    } */
    .intro4{
      display: flex;
      justify-content: space-evenly;
    }
    .intro4 {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 1rem; /* Adds space between items */
  }
  .intro4 > * {
    flex: 1 1 200px; /* Allows items to grow and shrink with a base width of 200px */
    max-width: 100%; /* Ensures items don't exceed container width */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

    @media only screen and (max-width: 480px) {
      /* .intro4{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
      } */
      .intro4 {
        gap: 0.5rem; /* Reduces space between items on very small screens */
    }

    .intro4 > * {
        flex: 1 1 100%; /* Items continue to take full width on very small screens */
    }
      .intro4 {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 1rem; /* Adds space between items */
    }
    .intro4 > * {
      flex: 1 1 200px; /* Allows items to grow and shrink with a base width of 200px */
      max-width: 100%; /* Ensures items don't exceed container width */
      box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  }
  @media only screen and (max-width: 640) {

  }
  
        .App-ola{
            width:250px;
            height:230px;
            padding:68px;
            border-radius: 20% ;
            -webkit-border-radius: 20% ;
            -moz-border-radius: 20% ;
            -ms-border-radius: 20% ;
            -o-border-radius: 20% ;
}
            .image-container {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .image-container img {
                object-fit: cover;
            }
         
        
        /* h3{
            color: white;
            .text-container {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 20px;
                align-items: center;
            }
        }
        h2 {
          color: rgb(178, 171, 171);
          text-shadow: 0 0 40px rgba(10, 114, 17, 0.8);
      }
      h3{
        color: rgb(178, 171, 171);
          text-shadow: 0 0 40px rgba(10, 114, 17, 0.8);
      }
      p{
        color: rgb(178, 171, 171);
          text-shadow: 0 0 40px rgba(10, 114, 17, 0.8);
      } */
      h2{
        color:white
        
    }
    h1 {
        color: white;
    }
    p{
      color: white;
    }
    .projects-title{
        display: flex;
    }
    h1{
        color: white;
        .text-container {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 20px;
            align-items: center;
        }
    }
    h2 {
        color: white;
        text-shadow: 0 0 40px 0 0 40px rgba(28, 31, 28, 0.8);
    }
    h3{
      color: white;
        text-shadow: 0 0 40px rgba(28, 31, 28, 0.8);
    }
        .port-1{
          width: 400px;
          height:450px;
          border: 3px solid transparent;
          padding: 20px 20px;
          background-color: #1B263B;
          border-radius:12px; 
          color: white;
       
      }
      .port-2{
        width: 400px;
        height:450px;
        border: 3px solid transparent;
        padding: 20px 20px;
        background-color: #1B263B;
        border-radius:12px; 
        color: white;
     
    }
    .port-3{
      width: 400px;
      height:450px;
      border: 3px solid transparent;
      padding: 20px 20px;
      background-color: #1B263B;
      border-radius:12px; 
      color: white;
   
  }
  .port-4{
    width: 400px;
    height:450px;
    border: 3px solid transparent;
    padding: 20px 20px;
    background-color: #1B263B;
    border-radius:12px; 
    color: white;
 
}
      /* .resume-container{
        width:500px;
        height:400px;
      } */
      .resume-container{
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 20px;
        align-items: center;
      }
        .text-container{
           
            display:grid;
        }
        .navbar {
            background-color: #813636;
            color: #fff;
            padding: 15px 0;
          }
          .navbar-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 20px;
          }
          .navbar-logo a {
            color: #fff;
            text-decoration: none;
            font-size: 1.5rem;
          }
          .navbar-links {
            display: none;
            flex-direction: column;
            background-color: #6d6464;
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            padding: 20px 0;
          }
        
          .navbar-links.active {
            display: flex;
          }
        
          .navbar-toggle {
            display: block;
          }
        }
        .dropdown {
          position: relative;
        }
        
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #444;
          min-width: 160px;
          z-index: 1;
        }
        
        .dropdown-content a {
          color: #fff;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
        }
        
        .dropdown:hover .dropdown-content {
          display: block;
        }
      
        .navbar-logo {
          position: relative;
        }
        
        .navbar-logo .dropdown-content {
          display: none;
          position: absolute;
          background-color: #444;
          min-width: 160px;
          z-index: 1;
          top: 100%;
          left: 0;
        }
        
        .navbar-logo.active .dropdown-content {
          display: block;
        }
        
        .navbar-logo a {
          color: #fff;
          text-decoration: none;
        }
        
        .navbar-logo:hover .dropdown-content {
          display: block;
        }
        
          
        /* Styles for iPhones / .socio { / Update styles for iPhones / } .intro { / Update styles for iPhones / } .intro2 { / Update styles for iPhones / } .main-container1 { / Update styles for iPhones / } .App-ola { / Update styles for iPhones / } .port-1 { / Update styles for iPhones / } .port-2 { / Update styles for iPhones / } .port-3 { / Update styles for iPhones / } .port-4 { / Update styles for iPhones / } .main-container { / Update styles for iPhones / } .img1 { / Update styles for iPhones / } p { / Update styles for iPhones / } h3 { / Update styles for iPhones / } .projects-title { / Update styles for iPhones }  */

    /* @media only screen and (min-width: 481px) and (max-width: 1024px) {
        .text-container{
           
            display:grid;
        }
        h2 {
          color: rgb(178, 171, 171);
          text-shadow: 0 0 40px rgba(10, 114, 17, 0.8);
      }
      h3{
        color: rgb(178, 171, 171);
          text-shadow: 0 0 40px rgba(10, 114, 17, 0.8);
      }
        .navbar {
            background-color: #813636;
            color: #fff;
            padding: 15px 0;
          }
          .navbar-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 20px;
          }
          .resume-container{
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 20px;
            align-items: center;
          }
          .navbar-logo a {
            color: #fff;
            text-decoration: none;
            font-size: 1.5rem;
          }
          .navbar-links {
            display: none;
            flex-direction: column;
            background-color: #6d6464;
            position: absolute;
            top: 60px;
            left: 0;
            width: 100%;
            padding: 20px 0;
          }
          .port-1{
            width: 400px;
            height:400px;
            border: 3px solid transparent;
            padding: 20px 20px;
            background-color: #1B263B;
            border-radius:12px; 
            color: white;
         
        }
        
          .navbar-links.active {
            display: flex;
          }
        
          .navbar-toggle {
            display: block;
          }
        }
        .dropdown {
          position: relative;
        }
        
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #444;
          min-width: 160px;
          z-index: 1;
        }
        
        .dropdown-content a {
          color: #fff;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
        }
        
        .dropdown:hover .dropdown-content {
          display: block;
        }
      
        .navbar-logo {
          position: relative;
        }
        
        .navbar-logo .dropdown-content {
          display: none;
          position: absolute;
          background-color: #444;
          min-width: 160px;
          z-index: 1;
          top: 100%;
          left: 0;
        }
        
        .navbar-logo.active .dropdown-content {
          display: block;
        }
        
        .navbar-logo a {
          color: #fff;
          text-decoration: none;
        }
        
        .navbar-logo:hover .dropdown-content {
          display: block;
        } */
        /* Iphone SE / 11 etc */
        
        @media (max-width: 767px) {
          .intro4 {
              grid-template-columns: 1fr; /* Single column layout for mobile */
          }
      }
        @media screen
    and (max-device-width: 640px) {
      .intro4 {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem; /* Adds space between items */
        justify-content: center; /* Center items horizontally */
    }
      h2{
        color:white
        
    }
    h1 {
        color: white;
    }
    p{
      color: white;
    }
    .projects-title{
        display: flex;
    }
    h1{
        color: white;
        .text-container {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 20px;
            align-items: center;
        }
    }
    h2 {
        color: white;
        text-shadow: 0 0 40px 0 0 40px rgba(28, 31, 28, 0.8);
    }
    h3{
      color: white;
        text-shadow: 0 0 40px rgba(28, 31, 28, 0.8);
    }
        .port-1{
            width: 330px;
            height:400px;
            border: 3px solid transparent;
            padding:20px 20px;
            background-color: #1B263B;
            border-radius:10px;
            color: white;
            -webkit-border-radius:10px;
            -moz-border-radius:10px;
            -ms-border-radius:10px;
            -o-border-radius:10px;
}
 .port-1{
            width: 330px;
            height:400px;
            border: 3px solid transparent;
            padding:20px 20px;
            background-color: #1B263B;
            border-radius:10px;
            color: white;
            -webkit-border-radius:10px;
            -moz-border-radius:10px;
            -ms-border-radius:10px;
            -o-border-radius:10px;
}
}
/* My Phone Media Querie */
@media screen
and (max-device-width: 896px) {
  h2{
    color:white
    
}
h1 {
    color: white;
}
p{
  color: white;
}
.projects-title{
    display: flex;
}
h1{
    color: white;
    .text-container {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 20px;
        align-items: center;
    }
}
h2 {
    color: white;
    text-shadow: 0 0 40px 0 0 40px rgba(28, 31, 28, 0.8);
}
h3{
  color: white;
    text-shadow: 0 0 40px rgba(28, 31, 28, 0.8);
}
    .port-1{
        width: 370px;
        height:400px;
        border: 3px solid transparent;
        padding:20px 20px;
        background-color: #1B263B;
        border-radius:10px;
        color: white;
        -webkit-border-radius:10px;
        -moz-border-radius:10px;
        -ms-border-radius:10px;
        -o-border-radius:10px;
}
}
@media only screen 
    and (width: 414px) 
    and (height: 896px) 
    and (-webkit-device-pixel-ratio: 3) {
      .port-1{
        width: 370px;
        height:400px;
        border: 3px solid transparent;
        padding:20px 20px;
        background-color: #1B263B;
        border-radius:10px;
        color: white;
        -webkit-border-radius:10px;
        -moz-border-radius:10px;
        -ms-border-radius:10px;
        -o-border-radius:10px;
}
     }
/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) 
    and (orientation: landscape) {
     
      h2{
        color:white
        
    }
    h1 {
        color: white;
    }
    p{
      color: white;
    }
    .projects-title{
        display: flex;
    }
    h1{
        color: white;
        .text-container {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 20px;
            align-items: center;
        }
    }
    h2 {
        color: white;
        text-shadow: 0 0 40px 0 0 40px rgba(28, 31, 28, 0.8);
    }
    h3{
      color: white;
        text-shadow: 0 0 40px rgba(28, 31, 28, 0.8);
    }
    .gfg-div {
        width: 400px;
        height: 200px;
        background-color: #333;
        color: black;
    }

}
        @media only screen and (min-device-width: 768px){}
        
        
        
        /* Styles for tablets / .socio { / Update styles for tablets / } .intro { / Update styles for tablets / } .intro2 { / Update styles for tablets / } .main-container1 { / Update styles for tablets / } .App-ola { / Update styles for tablets / } .port-1 { / Update styles for tablets / } .port-2 { / Update styles for tablets / } .port-3 { / Update styles for tablets / } .port-4 { / Update styles for tablets / } .main-container { / Update styles for tablets / } .img1 { / Update styles for tablets / } p { / Update styles for tablets / } h3 { / Update styles for tablets / } .projects-title { / Update styles for tablets } */

    @media only screen and (min-width: 1025px) { /* Styles for laptops / .socio { / Update styles for laptops / } .intro { / Update styles for laptops / } .intro2 { / Update styles for laptops / } .main-container1 { / Update styles for laptops / } .App-ola { / Update styles for laptops / } .port-1 { / Update styles for laptops / } .port-2 { / Update styles for laptops / } .port-3 { / Update styles for laptops / } .port-4 { / Update styles for laptops / } .main-container { / Update styles for laptops / } .img1 { / Update styles for laptops / } p { / Update styles for laptops / } h3 { / Update styles for laptops / } .projects-title { / Update styles for laptops */
    
    
    
    
    }

