.snake{
    width:500px;
}
#projects {
    padding: 2em;
    background-color: #f9f9f9;
  }
  
  .project {
    margin-bottom: 2em;
  }
  
  .project h2 {
    font-size: 2em;
    color: #333;
  }
  
  .project p, .project ul {
    font-size: 1em;
    color: #666;
  }
  
  .project-visuals img, .project-visuals video {
    max-width: 100%;
    height: auto;
  }
  
  .project-links a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .project-links a:hover {
    text-decoration: underline;
  }