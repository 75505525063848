li{
    color:black;
    font-size: 23px;
  }
 /* General Styles for Charts */
.chart-container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 10px;
  }
  
  /* Bar Chart Styles */
  .bar-chart {
    position: relative;
    width: 100%;
    height: 400px; /* Default height for larger screens */
  }
  
  .bar {
    fill: steelblue;
    stroke: #fff;
    stroke-width: 1px;
  }
  
  .bar:hover {
    fill: orange;
  }
  
  /* Bubble Chart Styles */
  .bubble-chart {
    position: relative;
    width: 100%;
    height: 500px; /* Default height for larger screens */
  }
  
  .bubble {
    fill-opacity: 0.6;
  }
  
  .bubble:hover {
    fill-opacity: 1;
    stroke: #a22727;
    stroke-width: 1px;
  }
  
  /* iPhone Specific Adjustments */
  @media (max-width: 414px) { /* iPhone 11, iPhone 12, iPhone 13, iPhone 14 */
    .bar-chart {
      height: 300px; /* Reduced height for smaller screens */
      width: 90%; /* Ensure width is appropriate for iPhone screens */
      margin: 0 auto; /* Center align */
    }
  
    .bubble-chart {
      height: 400px; /* Reduced height for smaller screens */
      width: 90%; /* Ensure width is appropriate for iPhone screens */
      margin: 0 auto; /* Center align */
    }
  
    .chart-container h1 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    .x-axis text, .y-axis text {
      font-size: 10px;
    }
  
    .bar {
      stroke-width: 0.5px; /* Thinner strokes for bars */
    }
  
    .bubble {
      stroke-width: 0.5px; /* Thinner strokes for bubbles */
    }
  }
  @media only screen and (max-width: 480) {
    .bar-chart {
        height: 350px; /* Slightly reduced height for tablets */
        width: 120px; /* Ensure width is appropriate for tablet screens */
        margin: 0 auto; /* Center align */
      }
    
      .bubble-chart {
        height: 450px; /* Slightly reduced height for tablets */
        width: 120px; /* Ensure width is appropriate for tablet screens */
        margin: 0 auto; /* Center align */
      }
    
      .chart-container h1 {
        font-size: 20px;
        margin-bottom: 15px;
      }
    
      .x-axis text, .y-axis text {
        font-size: 12px;
      }
    
      .bar {
        stroke-width: 0.75px; /* Thinner strokes for bars */
      }
    
      .bubble {
        stroke-width: 0.75px; /* Thinner strokes for bubbles */
      }
  }
  /* Tablet Responsiveness */
  @media (min-width: 415px) and (max-width: 768px) {
    .bar-chart {
      height: 350px; /* Slightly reduced height for tablets */
      width: 95%; /* Ensure width is appropriate for tablet screens */
      margin: 0 auto; /* Center align */
    }
  
    .bubble-chart {
      height: 450px; /* Slightly reduced height for tablets */
      width: 95%; /* Ensure width is appropriate for tablet screens */
      margin: 0 auto; /* Center align */
    }
  
    .chart-container h1 {
      font-size: 20px;
      margin-bottom: 15px;
    }
  
    .x-axis text, .y-axis text {
      font-size: 12px;
    }
  
    .bar {
      stroke-width: 0.75px; /* Thinner strokes for bars */
    }
  
    .bubble {
      stroke-width: 0.75px; /* Thinner strokes for bubbles */
    }
  }
  
  /* Desktop Responsiveness */
  @media (min-width: 769px) {
    .bar-chart {
      height: 400px; /* Default height for larger screens */
      width: 100%; /* Default width for larger screens */
    }
  
    .bubble-chart {
      height: 500px; /* Default height for larger screens */
      width: 100%; /* Default width for larger screens */
    }
  
    .chart-container h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .x-axis text, .y-axis text {
      font-size: 14px;
    }
  
    .bar {
      stroke-width: 1px; /* Default strokes for bars */
    }
  
    .bubble {
      stroke-width: 1px; /* Default strokes for bubbles */
    }
  }
  