.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .heading {
    color: #333;
    text-align: center;
  }
  
  .form {
    background-color: #1e1b1b;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .paragraph {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  /* Button Styles */
  .button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .button:focus {
    outline: none;
  }
  