/* Styling for the bell curve chart */
.bell-curve {
    fill: none;
    stroke: steelblue;
    stroke-width: 2;
  }
  
  /* Styling for the axes */
  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }
  
  .axis text {
    font-size: 12px;
  }
  
  .info {
    margin-top: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .info strong {
    color: #555;
  }
  