/* General Styles for Charts */
.chart-container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 10px;
  }
  
  /* Bar Chart Styles */
  .bar-chart {
    position: relative;
    width: 100%;
    height: auto; /* Make height responsive */
    max-height: 400px; /* Limit max height */
  }
  
  .bar {
    fill: steelblue;
    stroke: #fff;
    stroke-width: 1px;
  }
  
  .bar:hover {
    fill: orange;
  }
  
  /* Bubble Chart Styles */
  .bubble-chart {
    position: relative;
    width: 100%;
    height: auto; /* Make height responsive */
    max-height: 500px; /* Limit max height */
  }
  
  .bubble {
    fill-opacity: 0.6;
  }
  
  .bubble:hover {
    fill-opacity: 1;
    stroke: #000;
    stroke-width: 1px;
  }
  .container {
    max-width: 100%;
    margin: auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  select {
    margin-bottom: 20px;
  }
  
  .bar {
    cursor: pointer;
    transition: fill 0.3s;
  }
  .bar {
    transition: fill 0.3s ease;
  }
  
  .bar:hover {
    fill: #a4c8a6;
  }
  
  .tooltip {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    opacity: 0;
    pointer-events: none;
  }
  
  .axis-label {
    font-size: 12px;
    font-weight: bold;
  }
  
  /* Responsive Design Adjustments */
  @media (max-width: 768px) {
    .bar-chart, .bubble-chart {
      height: 300px; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .bar-chart, .bubble-chart {
      height: 200px; /* Further adjust height for very small screens */
    }
  }
  
  